export const AUTH_CONSTANTS = {
  SESSION_KEY: 'auth_session',
  SESSION_DURATION: 24 * 60 * 60 * 1000, // 24 hours
  ERROR_MESSAGES: {
    INVALID_CREDENTIALS: 'Identifiants invalides',
    ACCOUNT_DISABLED: 'Ce compte est désactivé',
    REQUIRED_FIELDS: 'Les identifiants sont requis',
    LOGIN_ERROR: 'Erreur lors de la connexion',
    UNAUTHORIZED: 'Accès non autorisé'
  }
} as const;