export const ROUTES = {
  HOME: '/',
  ADMIN: {
    LOGIN: '/admin',
    DASHBOARD: '/admin/dashboard',
    EVENTS: '/admin/events',
    ATHLETES: '/admin/athletes',
    WODS: '/admin/wods',
    RANKINGS: '/admin/rankings',
    VOLUNTEERS: '/admin/volunteers',
    ACCOUNTS: '/admin/accounts'
  }
} as const;

export const PROTECTED_ROUTES = Object.values(ROUTES.ADMIN).filter(
  route => route !== ROUTES.ADMIN.LOGIN
);