import { EventFormData } from '../../types/event';
import { isValidDateRange } from '../date';

export function validateEventData(data: Partial<EventFormData>): boolean {
  return validateBasicInfo(data) && 
         validateLocation(data) && 
         validateDates(data) && 
         validateTeamSize(data) &&
         validateLevels(data);
}

function validateBasicInfo(data: Partial<EventFormData>): boolean {
  if (!data.name?.trim()) return false;
  if (!data.type) return false;
  if (!data.boxName?.trim()) return false;
  if (!data.maxParticipants || data.maxParticipants <= 0) return false;
  return true;
}

function validateLocation(data: Partial<EventFormData>): boolean {
  if (!data.location) return false;
  if (!data.location.name?.trim()) return false;
  if (!data.location.address?.trim()) return false;
  if (!data.location.city?.trim()) return false;
  if (!data.location.postalCode?.trim()) return false;
  return true;
}

function validateDates(data: Partial<EventFormData>): boolean {
  if (!data.date || !data.registrationDeadline) return false;
  return isValidDateRange(data.registrationDeadline, data.date);
}

function validateTeamSize(data: Partial<EventFormData>): boolean {
  if (data.type === 'team' && (!data.teamSize || data.teamSize <= 0)) {
    return false;
  }
  return true;
}

function validateLevels(data: Partial<EventFormData>): boolean {
  if (!data.allowedLevels?.length) return false;
  const validLevels = ['rx', 'scaled', 'beginner'];
  return data.allowedLevels.every(level => validLevels.includes(level));
}