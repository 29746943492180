import React from 'react';

export function Footer() {
  return (
    <footer className="text-center py-8 mt-16 border-t border-white/10">
      <a 
        href="https://www.meilleurecommunication.com" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sm text-gray-400 hover:text-gray-300 transition-colors"
      >
        Powered by MC_x_CC
      </a>
    </footer>
  );
}