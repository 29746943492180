import { useState } from 'react';
import { Admin, AdminFormData } from '../../types/admin';

interface UseAdminFormProps {
  initialData?: Partial<Admin>;
  onSubmit: (data: AdminFormData) => void;
}

export function useAdminForm({ initialData, onSubmit }: UseAdminFormProps) {
  const [formData, setFormData] = useState<AdminFormData>({
    username: initialData?.username || '',
    email: initialData?.email || '',
    password: '',
    role: initialData?.role || 'organizer',
    boxName: initialData?.boxName || ''
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return {
    formData,
    handleChange,
    handleSubmit
  };
}