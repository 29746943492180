import { isValid } from 'date-fns';

export function toLocalISOString(date: Date | string): string {
  try {
    const d = typeof date === 'string' ? new Date(date) : date;
    if (!isValid(d)) {
      return new Date().toISOString().slice(0, 16);
    }
    
    const offset = d.getTimezoneOffset();
    const localDate = new Date(d.getTime() - (offset * 60 * 1000));
    return localDate.toISOString().slice(0, 16);
  } catch (error) {
    console.error('Error converting to local ISO string:', error);
    return new Date().toISOString().slice(0, 16);
  }
}

export function fromUTCtoLocal(utcDate: string): string {
  try {
    const date = new Date(utcDate);
    if (!isValid(date)) {
      return new Date().toISOString().slice(0, 16);
    }
    return toLocalISOString(date);
  } catch (error) {
    console.error('Error converting UTC to local date:', error);
    return new Date().toISOString().slice(0, 16);
  }
}