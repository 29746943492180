import React, { useState } from 'react';
import { Event, EventFormData, DEFAULT_EVENT_DATA } from '../../types/event';
import { fromUTCtoLocal } from '../../utils/date';
import { useForm } from '../../hooks/useForm';
import { validateEventForm } from '../../utils/validation';
import { ErrorMessage } from '../common/ErrorMessage';

interface EventFormProps {
  initialData?: Event;
  onSubmit: (data: EventFormData) => void;
  onCancel: () => void;
}

export function EventForm({ initialData, onSubmit, onCancel }: EventFormProps) {
  const initialValues: EventFormData = {
    ...DEFAULT_EVENT_DATA,
    ...initialData,
    date: initialData?.date ? fromUTCtoLocal(initialData.date) : DEFAULT_EVENT_DATA.date,
    registrationDeadline: initialData?.registrationDeadline ? 
      fromUTCtoLocal(initialData.registrationDeadline) : 
      DEFAULT_EVENT_DATA.registrationDeadline,
    boxName: initialData?.boxName || '',
    location: {
      name: initialData?.location?.name || '',
      address: initialData?.location?.address || '',
      city: initialData?.location?.city || '',
      postalCode: initialData?.location?.postalCode || ''
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm<EventFormData>({
    initialValues,
    onSubmit,
    validate: validateEventForm
  });

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nom de l'événement
          </label>
          <input
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
          {errors.name && <ErrorMessage message={errors.name} />}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Box organisatrice
          </label>
          <input
            type="text"
            name="boxName"
            value={values.boxName}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
          {errors.boxName && <ErrorMessage message={errors.boxName} />}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Type d'événement
          </label>
          <select
            name="type"
            value={values.type}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Sélectionner un type</option>
            <option value="individual">Individuel</option>
            <option value="team">Équipe</option>
          </select>
          {errors.type && <ErrorMessage message={errors.type} />}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nombre maximum de participants
          </label>
          <input
            type="number"
            name="maxParticipants"
            value={values.maxParticipants}
            onChange={handleChange}
            min="1"
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
          {errors.maxParticipants && <ErrorMessage message={errors.maxParticipants} />}
        </div>

        {values.type === 'team' && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Taille des équipes
            </label>
            <input
              type="number"
              name="teamSize"
              value={values.teamSize}
              onChange={handleChange}
              min="2"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors.teamSize && <ErrorMessage message={errors.teamSize} />}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date de l'événement
          </label>
          <input
            type="datetime-local"
            name="date"
            value={values.date}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
          {errors.date && <ErrorMessage message={errors.date} />}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date limite d'inscription
          </label>
          <input
            type="datetime-local"
            name="registrationDeadline"
            value={values.registrationDeadline}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
          {errors.registrationDeadline && <ErrorMessage message={errors.registrationDeadline} />}
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Lieu de l'événement</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nom du lieu
            </label>
            <input
              type="text"
              name="location.name"
              value={values.location.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors['location.name'] && <ErrorMessage message={errors['location.name']} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Adresse
            </label>
            <input
              type="text"
              name="location.address"
              value={values.location.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors['location.address'] && <ErrorMessage message={errors['location.address']} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Code postal
            </label>
            <input
              type="text"
              name="location.postalCode"
              value={values.location.postalCode}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors['location.postalCode'] && <ErrorMessage message={errors['location.postalCode']} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Ville
            </label>
            <input
              type="text"
              name="location.city"
              value={values.location.city}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors['location.city'] && <ErrorMessage message={errors['location.city']} />}
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          name="description"
          value={values.description}
          onChange={handleChange}
          rows={4}
          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        />
        {errors.description && <ErrorMessage message={errors.description} />}
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
        >
          Annuler
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
        >
          {initialData ? 'Mettre à jour' : 'Créer'}
        </button>
      </div>
    </form>
  );
}