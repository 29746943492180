import React from 'react';
import { Event } from '../../types/event';
import { Calendar, MapPin, Users, Clock, Building2, Trophy } from 'lucide-react';
import { formatDate } from '../../utils/date';

interface EventListProps {
  events: Event[];
  onEdit?: (event: Event) => void;
  onDelete?: (event: Event) => void;
}

export function EventList({ events, onEdit, onDelete }: EventListProps) {
  if (!events?.length) return null;

  const formatLocation = (location: Event['location']) => {
    if (!location) return 'Lieu non spécifié';
    
    const parts = [];
    if (location.name) parts.push(location.name);
    if (location.address) parts.push(location.address);
    if (location.postalCode || location.city) {
      parts.push(`${location.postalCode || ''} ${location.city || ''}`.trim());
    }
    
    return parts.join('\n');
  };

  return (
    <div className="space-y-6">
      {events.map(event => (
        <div 
          key={event.id}
          className="bg-white border border-gray-200 rounded-xl p-6 hover:border-gray-300 transition-all duration-200 shadow-sm hover:shadow-md"
        >
          <div className="flex justify-between items-start gap-6">
            <div className="space-y-6 flex-grow">
              <div>
                <div className="flex items-center gap-3 mb-3">
                  <h3 className="text-xl font-medium text-gray-900">{event.name}</h3>
                  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                    event.type === 'team' 
                      ? 'bg-purple-100 text-purple-700'
                      : 'bg-blue-100 text-blue-700'
                  }`}>
                    {event.type === 'team' ? 'Équipe' : 'Individuel'}
                  </span>
                </div>
                {event.description && (
                  <p className="text-gray-600">{event.description}</p>
                )}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center gap-2 text-sm">
                  <Calendar className="w-4 h-4 text-blue-500 flex-shrink-0" />
                  <span>{formatDate(event.date)}</span>
                </div>

                <div className="flex items-center gap-2 text-sm">
                  <Building2 className="w-4 h-4 text-blue-500 flex-shrink-0" />
                  <span className="font-medium">{event.boxName}</span>
                </div>

                <div className="flex items-start gap-2 text-sm md:col-span-2">
                  <MapPin className="w-4 h-4 text-blue-500 flex-shrink-0 mt-0.5" />
                  <div className="flex-grow whitespace-pre-line">
                    {formatLocation(event.location)}
                  </div>
                </div>

                <div className="flex items-center gap-2 text-sm">
                  <Users className="w-4 h-4 text-blue-500 flex-shrink-0" />
                  <span>
                    {event.type === 'team' 
                      ? `${event.maxParticipants} équipes de ${event.teamSize} athlètes`
                      : `${event.maxParticipants} athlètes`
                    }
                  </span>
                </div>

                <div className="flex items-center gap-2 text-sm">
                  <Clock className="w-4 h-4 text-blue-500 flex-shrink-0" />
                  <span>
                    Inscriptions jusqu'au {formatDate(event.registrationDeadline)}
                  </span>
                </div>

                <div className="flex items-center gap-2 text-sm">
                  <Trophy className="w-4 h-4 text-blue-500 flex-shrink-0" />
                  <div className="flex items-center gap-1.5">
                    {event.allowedLevels.map(level => (
                      <span 
                        key={level}
                        className={`px-2 py-0.5 rounded text-xs font-medium ${
                          level === 'rx' 
                            ? 'bg-blue-100 text-blue-700'
                            : level === 'scaled'
                            ? 'bg-orange-100 text-orange-700'
                            : 'bg-green-100 text-green-700'
                        }`}
                      >
                        {level.toUpperCase()}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {(onEdit || onDelete) && (
              <div className="flex flex-col gap-2">
                {onEdit && (
                  <button
                    onClick={() => onEdit(event)}
                    className="px-3 py-1.5 text-sm bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100"
                  >
                    Modifier
                  </button>
                )}
                {onDelete && (
                  <button
                    onClick={() => onDelete(event)}
                    className="px-3 py-1.5 text-sm bg-red-50 text-red-600 rounded-lg hover:bg-red-100"
                  >
                    Supprimer
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}