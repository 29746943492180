import React, { useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useAdmin } from '../../contexts/AdminContext';
import { AdminForm } from './AdminForm';
import { AdminList } from './AdminList';

export function AdminDashboard() {
  const {
    admins,
    isLoading,
    isCreating,
    editingAdmin,
    setIsCreating,
    setEditingAdmin,
    loadAdmins,
    createAdmin,
    updateAdmin,
    deleteAdmin,
    toggleStatus
  } = useAdmin();

  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-light">Gestion des comptes</h1>
        <button
          onClick={() => setIsCreating(true)}
          className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5" />
          Créer un compte
        </button>
      </div>

      {(isCreating || editingAdmin) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 max-w-2xl w-full">
            <h2 className="text-xl font-medium mb-6">
              {editingAdmin ? 'Modifier le compte' : 'Créer un compte'}
            </h2>
            <AdminForm
              initialData={editingAdmin || undefined}
              onSubmit={editingAdmin ? updateAdmin : createAdmin}
              onCancel={() => {
                setIsCreating(false);
                setEditingAdmin(null);
              }}
            />
          </div>
        </div>
      )}

      <AdminList
        admins={admins}
        onEdit={setEditingAdmin}
        onDelete={deleteAdmin}
        onToggleStatus={toggleStatus}
      />
    </div>
  );
}