import React from 'react';
import { Users, Trophy, Calendar } from 'lucide-react';
import { StatCard } from './StatCard';
import { useDashboardStats } from '../../../hooks/useDashboardStats';

export function DashboardStats() {
  const { stats, isLoading } = useDashboardStats();

  if (isLoading) {
    return <div className="animate-pulse">Loading stats...</div>;
  }

  const cards = [
    {
      title: 'Total Teams',
      value: stats.totalTeams,
      icon: Users,
      color: 'blue'
    },
    {
      title: 'Active Events',
      value: stats.activeEvents,
      icon: Calendar,
      color: 'green'
    },
    {
      title: 'Completed Events',
      value: stats.completedEvents,
      icon: Trophy,
      color: 'yellow'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {cards.map((card, index) => (
        <StatCard key={index} {...card} />
      ))}
    </div>
  );
}