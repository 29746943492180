import React, { useEffect } from 'react';
import { AdminForm, AdminList } from '../components/admin';
import { DashboardStats } from '../components/admin/dashboard';
import { useAdmin } from '../contexts/AdminContext';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { ErrorMessage } from '../components/common/ErrorMessage';

export default function AdminDashboard() {
  const {
    admins,
    isLoading,
    isCreating,
    editingAdmin,
    setIsCreating,
    setEditingAdmin,
    loadAdmins,
    createAdmin,
    updateAdmin,
    deleteAdmin,
    toggleStatus
  } = useAdmin();

  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <DashboardStats />

      <div className="mt-8">
        {(isCreating || editingAdmin) && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl p-6 max-w-2xl w-full">
              <h2 className="text-xl font-medium mb-6">
                {editingAdmin ? 'Modifier le compte' : 'Créer un compte'}
              </h2>
              <AdminForm
                initialData={editingAdmin}
                onSubmit={editingAdmin ? updateAdmin : createAdmin}
                onCancel={() => {
                  setIsCreating(false);
                  setEditingAdmin(null);
                }}
              />
            </div>
          </div>
        )}

        {admins.length === 0 ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">Aucun compte administrateur trouvé</p>
          </div>
        ) : (
          <AdminList
            admins={admins}
            onEdit={setEditingAdmin}
            onDelete={deleteAdmin}
            onToggleStatus={toggleStatus}
          />
        )}
      </div>
    </div>
  );
}