import React from 'react';
import { useForm } from '../../hooks/useForm';
import { ErrorMessage } from '../common/ErrorMessage';
import { validateRegistrationForm } from '../../utils/validation/registrationValidation';
import { RegistrationFormData } from '../../types/registration';

interface RegistrationFormProps {
  eventId: string;
  teamSize: number;
  onSubmit: (data: RegistrationFormData) => Promise<void>;
  onCancel: () => void;
}

const initialValues: RegistrationFormData = {
  teamName: '',
  email: '',
  phone: '',
  boxLocation: '',
  athletes: []
};

export function RegistrationForm({ eventId, teamSize, onSubmit, onCancel }: RegistrationFormProps) {
  const { values, errors, isSubmitting, handleChange, handleSubmit } = useForm<RegistrationFormData>({
    initialValues: {
      ...initialValues,
      athletes: Array(teamSize).fill({
        name: '',
        gender: '',
        insta: '',
        tshirtSize: ''
      })
    },
    onSubmit,
    validate: validateRegistrationForm
  });

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="space-y-6">
        <h3 className="text-lg font-medium">Informations de l'équipe</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Nom de l'équipe
            </label>
            <input
              type="text"
              name="teamName"
              value={values.teamName}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors.teamName && <ErrorMessage message={errors.teamName} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Box CrossFit
            </label>
            <input
              type="text"
              name="boxLocation"
              value={values.boxLocation}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors.boxLocation && <ErrorMessage message={errors.boxLocation} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors.email && <ErrorMessage message={errors.email} />}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Téléphone
            </label>
            <input
              type="tel"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              required
            />
            {errors.phone && <ErrorMessage message={errors.phone} />}
          </div>
        </div>
      </div>

      <div className="space-y-6">
        <h3 className="text-lg font-medium">Athlètes</h3>
        {values.athletes.map((athlete, index) => (
          <div key={index} className="bg-gray-50 p-6 rounded-lg space-y-4">
            <h4 className="font-medium">Athlète {index + 1}</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nom et prénom
                </label>
                <input
                  type="text"
                  name={`athletes.${index}.name`}
                  value={athlete.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  required
                />
                {errors[`athletes.${index}.name`] && (
                  <ErrorMessage message={errors[`athletes.${index}.name`]} />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Genre
                </label>
                <select
                  name={`athletes.${index}.gender`}
                  value={athlete.gender}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  required
                >
                  <option value="">Sélectionner</option>
                  <option value="H">Homme</option>
                  <option value="F">Femme</option>
                </select>
                {errors[`athletes.${index}.gender`] && (
                  <ErrorMessage message={errors[`athletes.${index}.gender`]} />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Instagram (optionnel)
                </label>
                <input
                  type="text"
                  name={`athletes.${index}.insta`}
                  value={athlete.insta}
                  onChange={handleChange}
                  placeholder="@username"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                />
                {errors[`athletes.${index}.insta`] && (
                  <ErrorMessage message={errors[`athletes.${index}.insta`]} />
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Taille T-shirt
                </label>
                <select
                  name={`athletes.${index}.tshirtSize`}
                  value={athlete.tshirtSize}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  required
                >
                  <option value="">Sélectionner</option>
                  <option value="XS">XS</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
                {errors[`athletes.${index}.tshirtSize`] && (
                  <ErrorMessage message={errors[`athletes.${index}.tshirtSize`]} />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
        >
          Annuler
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-lg"
        >
          {isSubmitting ? 'Inscription en cours...' : 'S\'inscrire'}
        </button>
      </div>
    </form>
  );
}