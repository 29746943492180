import React from 'react';

interface FormActionsProps {
  onCancel: () => void;
  submitLabel: string;
}

export function FormActions({ onCancel, submitLabel }: FormActionsProps) {
  return (
    <div className="flex justify-end gap-3">
      <button
        type="button"
        onClick={onCancel}
        className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
      >
        Cancel
      </button>
      <button
        type="submit"
        className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg"
      >
        {submitLabel}
      </button>
    </div>
  );
}