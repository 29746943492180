import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { EventForm } from '../components/events/EventForm';
import { EventList } from '../components/events/EventList';
import { eventService } from '../services/eventService';
import { Event, EventFormData } from '../types/event';

export default function EventManagement() {
  const [events, setEvents] = useState<Event[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      const data = await eventService.getEvents();
      setEvents(data);
    } catch (error) {
      console.error('Error loading events:', error);
      toast.error('Erreur lors du chargement des événements');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateEvent = async (eventData: EventFormData) => {
    try {
      await eventService.createEvent(eventData);
      toast.success('Événement créé avec succès');
      setIsCreating(false);
      loadEvents();
    } catch (error) {
      console.error('Error creating event:', error);
      toast.error('Erreur lors de la création de l\'événement');
    }
  };

  const handleUpdateEvent = async (eventData: EventFormData) => {
    if (!editingEvent) return;

    try {
      await eventService.updateEvent(editingEvent.id, eventData);
      toast.success('Événement mis à jour avec succès');
      setEditingEvent(null);
      loadEvents();
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error('Erreur lors de la mise à jour de l\'événement');
    }
  };

  const handleDeleteEvent = async (event: Event) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet événement ?')) {
      return;
    }

    try {
      await eventService.deleteEvent(event.id);
      toast.success('Événement supprimé avec succès');
      loadEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Erreur lors de la suppression de l\'événement');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-light">Gestion des événements</h1>
        <button
          onClick={() => setIsCreating(true)}
          className="inline-flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5" />
          Créer un événement
        </button>
      </div>

      {(isCreating || editingEvent) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-xl font-medium mb-6">
              {editingEvent ? 'Modifier l\'événement' : 'Créer un événement'}
            </h2>
            <EventForm
              initialData={editingEvent || undefined}
              onSubmit={editingEvent ? handleUpdateEvent : handleCreateEvent}
              onCancel={() => {
                setIsCreating(false);
                setEditingEvent(null);
              }}
            />
          </div>
        </div>
      )}

      <div className="space-y-6">
        {events.length === 0 ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <p className="text-gray-500">Aucun événement créé</p>
          </div>
        ) : (
          <EventList
            events={events}
            onEdit={setEditingEvent}
            onDelete={handleDeleteEvent}
          />
        )}
      </div>
    </div>
  );
}