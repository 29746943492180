import { supabase } from '../lib/supabase';
import { Event, EventFormData } from '../types/event';
import { validateEventData, formatEventData } from '../utils/event';

class EventService {
  async getEvents(): Promise<Event[]> {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .order('date', { ascending: true });

    if (error) throw error;
    return (data || []).map(this.mapEventFromDb);
  }

  async getEvent(id: string): Promise<Event> {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return this.mapEventFromDb(data);
  }

  async createEvent(eventData: EventFormData): Promise<Event> {
    if (!validateEventData(eventData)) {
      throw new Error('Invalid event data');
    }

    const formattedData = formatEventData(eventData);

    const { data, error } = await supabase
      .from('events')
      .insert([formattedData])
      .select()
      .single();

    if (error) throw error;
    return this.mapEventFromDb(data);
  }

  async updateEvent(id: string, eventData: Partial<EventFormData>): Promise<Event> {
    if (!validateEventData(eventData)) {
      throw new Error('Invalid event data');
    }

    const formattedData = formatEventData(eventData);

    const { data, error } = await supabase
      .from('events')
      .update(formattedData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return this.mapEventFromDb(data);
  }

  async deleteEvent(id: string): Promise<void> {
    const { error } = await supabase
      .from('events')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }

  private mapEventFromDb(data: any): Event {
    return {
      id: data.id,
      name: data.name,
      description: data.description || '',
      type: data.type,
      date: data.date,
      boxName: data.box_name,
      location: {
        name: data.location?.name || '',
        address: data.location?.address || '',
        city: data.location?.city || '',
        postalCode: data.location?.postal_code || ''
      },
      maxParticipants: data.max_participants,
      teamSize: data.team_size,
      allowedLevels: data.allowed_levels || ['rx', 'scaled'],
      registrationDeadline: data.registration_deadline,
      requiresQualification: data.requires_qualification || false,
      status: data.status,
      createdAt: data.created_at,
      updatedAt: data.updated_at
    };
  }
}

export const eventService = new EventService();