import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AppRoutes from './routes';
import { AdminProvider } from './contexts/AdminContext';
import { ErrorBoundary } from './components/common/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AdminProvider>
          <Toaster 
            position="top-right"
            toastOptions={{
              duration: 4000,
              style: {
                background: '#f9fafb',
                color: '#111827',
                border: '1px solid #e5e7eb'
              }
            }}
          />
          <div className="min-h-screen bg-gray-50">
            <AppRoutes />
          </div>
        </AdminProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;