import React from 'react';
import { Admin, AdminFormData } from '../../types/admin';
import { FormInput } from './form/FormInput';
import { FormSelect } from './form/FormSelect';
import { FormActions } from './form/FormActions';
import { useAdminForm } from '../../hooks/admin/useAdminForm';

interface AdminFormProps {
  initialData?: Partial<Admin>;
  onSubmit: (data: AdminFormData) => void;
  onCancel: () => void;
}

export function AdminForm({ initialData, onSubmit, onCancel }: AdminFormProps) {
  const { formData, handleChange, handleSubmit } = useAdminForm({
    initialData,
    onSubmit
  });

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormInput
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
        />

        <FormInput
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <FormInput
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          required={!initialData}
          minLength={6}
        />

        <FormSelect
          label="Role"
          name="role"
          value={formData.role}
          onChange={handleChange}
          options={[
            { value: 'organizer', label: 'Organizer' },
            { value: 'admin', label: 'Admin' }
          ]}
          required
        />

        <FormInput
          label="Box Name"
          name="boxName"
          value={formData.boxName}
          onChange={handleChange}
        />
      </div>

      <FormActions
        onCancel={onCancel}
        submitLabel={initialData ? 'Update' : 'Create'}
      />
    </form>
  );
}