import React from 'react';
import { Dumbbell } from 'lucide-react';

export default function WodManagement() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <div className="flex items-center gap-3 mb-8">
        <Dumbbell className="w-6 h-6 text-purple-500" />
        <h1 className="text-2xl font-light">Gestion des WODs</h1>
      </div>

      <div className="bg-white border border-gray-200 rounded-xl p-8">
        <p className="text-gray-600">
          Cette section est en cours de développement. Elle permettra de créer et gérer les WODs pour chaque événement.
        </p>
      </div>
    </div>
  );
}