import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, MapPin, Users } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { Event } from '../types/event';
import { RegistrationFormData } from '../types/registration';
import { RegistrationForm } from '../components/events/RegistrationForm';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { ErrorMessage } from '../components/common/ErrorMessage';
import { eventService } from '../services/event/eventService';
import { registrationService } from '../services/registration/registrationService';
import { formatDate } from '../utils/date';

export default function EventRegistration() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      loadEvent(id);
    }
  }, [id]);

  const loadEvent = async (eventId: string) => {
    try {
      const eventData = await eventService.getEvent(eventId);
      setEvent(eventData);
    } catch (error) {
      console.error('Error loading event:', error);
      setError('Impossible de charger les détails de l\'événement');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegistration = async (formData: RegistrationFormData) => {
    if (!event || !id) return;
    
    try {
      await registrationService.createRegistration(id, formData);
      toast.success('Inscription envoyée avec succès');
      navigate(`/events/${id}`);
    } catch (error: any) {
      toast.error(error.message || 'Erreur lors de l\'inscription');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <ErrorMessage message={error || 'Événement non trouvé'} />
          <button
            onClick={() => navigate(-1)}
            className="mt-4 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg inline-flex items-center gap-2"
          >
            <ArrowLeft className="w-4 h-4" />
            Retour
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <button
          onClick={() => navigate(-1)}
          className="mb-8 px-4 py-2 bg-white hover:bg-gray-100 rounded-lg inline-flex items-center gap-2 border border-gray-200"
        >
          <ArrowLeft className="w-4 h-4" />
          Retour
        </button>

        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <h1 className="text-2xl font-medium mb-4">{event.name}</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-center gap-2 text-gray-600">
                <Calendar className="w-5 h-5 text-blue-500" />
                <span>{formatDate(event.date)}</span>
              </div>

              <div className="flex items-center gap-2 text-gray-600">
                <MapPin className="w-5 h-5 text-blue-500" />
                <span>{event.location.name}</span>
              </div>

              <div className="flex items-center gap-2 text-gray-600">
                <Users className="w-5 h-5 text-blue-500" />
                <span>
                  {event.type === 'team' 
                    ? `${event.maxParticipants} équipes de ${event.teamSize} athlètes`
                    : `${event.maxParticipants} athlètes`
                  }
                </span>
              </div>
            </div>
          </div>

          <div className="p-6">
            <RegistrationForm
              eventId={id}
              teamSize={event.teamSize || 1}
              onSubmit={handleRegistration}
              onCancel={() => navigate(-1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}