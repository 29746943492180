import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export function DigitalClock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="font-mono text-sm tracking-wider text-gray-900">
      <div className="flex items-center gap-1.5">
        <span className="text-gray-900">16</span>
        <span className="text-gray-900">NOV</span>
        <span className="text-gray-900">2024</span>
        <span className="mx-2 text-gray-400">|</span>
        <span className="tabular-nums text-gray-900">{format(time, 'HH:mm')}</span>
        <span className="tabular-nums text-gray-900 animate-pulse">{format(time, 'ss')}</span>
      </div>
    </div>
  );
}