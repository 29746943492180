import { supabase } from '../../lib/supabase';
import { AuthError } from '../../types/errors';
import { AUTH_CONSTANTS } from '../../constants/auth';
import { ROLES } from '../../constants/roles';

interface AuthSession {
  isAuthenticated: boolean;
  id: string;
  username: string;
  role: string;
  expiresAt: number;
}

class AuthService {
  private getSession(): AuthSession | null {
    try {
      const sessionData = sessionStorage.getItem(AUTH_CONSTANTS.SESSION_KEY);
      if (!sessionData) return null;
      return JSON.parse(sessionData);
    } catch {
      return null;
    }
  }

  private setSession(session: AuthSession): void {
    sessionStorage.setItem(AUTH_CONSTANTS.SESSION_KEY, JSON.stringify(session));
  }

  private clearSession(): void {
    sessionStorage.removeItem(AUTH_CONSTANTS.SESSION_KEY);
  }

  async login(username: string, password: string): Promise<void> {
    try {
      if (!username?.trim() || !password?.trim()) {
        throw new AuthError(AUTH_CONSTANTS.ERROR_MESSAGES.REQUIRED_FIELDS);
      }

      const { data, error } = await supabase
        .from('admins')
        .select('id, username, role, status')
        .eq('username', username.trim())
        .eq('password', password.trim())
        .single();

      if (error || !data) {
        throw new AuthError(AUTH_CONSTANTS.ERROR_MESSAGES.INVALID_CREDENTIALS);
      }

      if (data.status === 'inactive') {
        throw new AuthError(AUTH_CONSTANTS.ERROR_MESSAGES.ACCOUNT_DISABLED);
      }

      // Create session
      this.setSession({
        isAuthenticated: true,
        id: data.id,
        username: data.username,
        role: data.role || ROLES.ORGANIZER,
        expiresAt: Date.now() + AUTH_CONSTANTS.SESSION_DURATION
      });

      // Update last login
      await supabase
        .from('admins')
        .update({ last_login: new Date().toISOString() })
        .eq('id', data.id);
    } catch (error) {
      console.error('Login error:', error);
      throw error instanceof AuthError ? error : new AuthError(AUTH_CONSTANTS.ERROR_MESSAGES.LOGIN_ERROR);
    }
  }

  isAuthenticated(): boolean {
    const session = this.getSession();
    if (!session) return false;

    if (Date.now() > session.expiresAt) {
      this.clearSession();
      return false;
    }

    return session.isAuthenticated;
  }

  getRole(): string | null {
    const session = this.getSession();
    return session?.role || null;
  }

  getUserId(): string | null {
    const session = this.getSession();
    return session?.id || null;
  }

  isSuperAdmin(): boolean {
    const role = this.getRole();
    return role === ROLES.SUPERADMIN;
  }

  isAdmin(): boolean {
    const role = this.getRole();
    return role === ROLES.ADMIN || role === ROLES.SUPERADMIN;
  }

  logout(): void {
    this.clearSession();
  }
}

export const authService = new AuthService();