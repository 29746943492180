import React, { createContext, useContext, useState, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { Admin, AdminFormData } from '../types/admin';
import { adminService } from '../services/admin/adminService';
import { authService } from '../services/auth/auth.service';

interface AdminContextType {
  admins: Admin[];
  isLoading: boolean;
  isCreating: boolean;
  editingAdmin: Admin | null;
  setIsCreating: (value: boolean) => void;
  setEditingAdmin: (admin: Admin | null) => void;
  loadAdmins: () => Promise<void>;
  createAdmin: (data: AdminFormData) => Promise<void>;
  updateAdmin: (data: AdminFormData) => Promise<void>;
  deleteAdmin: (admin: Admin) => Promise<void>;
  toggleStatus: (admin: Admin) => Promise<void>;
}

const AdminContext = createContext<AdminContextType | null>(null);

export function AdminProvider({ children }: { children: React.ReactNode }) {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [editingAdmin, setEditingAdmin] = useState<Admin | null>(null);

  const loadAdmins = useCallback(async () => {
    try {
      const data = await adminService.getAdmins();
      setAdmins(data);
    } catch (error) {
      console.error('Error loading admins:', error);
      toast.error('Erreur lors du chargement des comptes');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const createAdmin = async (adminData: AdminFormData) => {
    try {
      await adminService.createAdmin(adminData);
      toast.success('Compte créé avec succès');
      setIsCreating(false);
      loadAdmins();
    } catch (error) {
      console.error('Error creating admin:', error);
      toast.error('Erreur lors de la création du compte');
    }
  };

  const updateAdmin = async (adminData: AdminFormData) => {
    if (!editingAdmin) return;

    try {
      await adminService.updateAdmin(editingAdmin.id, adminData);
      toast.success('Compte mis à jour avec succès');
      setEditingAdmin(null);
      loadAdmins();
    } catch (error) {
      console.error('Error updating admin:', error);
      toast.error('Erreur lors de la mise à jour du compte');
    }
  };

  const deleteAdmin = async (admin: Admin) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce compte ?')) {
      return;
    }

    try {
      await adminService.deleteAdmin(admin.id);
      toast.success('Compte supprimé avec succès');
      loadAdmins();
    } catch (error) {
      console.error('Error deleting admin:', error);
      toast.error('Erreur lors de la suppression du compte');
    }
  };

  const toggleStatus = async (admin: Admin) => {
    try {
      const newStatus = admin.status === 'active' ? 'inactive' : 'active';
      await adminService.updateStatus(admin.id, newStatus);
      toast.success('Statut mis à jour avec succès');
      loadAdmins();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Erreur lors de la mise à jour du statut');
    }
  };

  return (
    <AdminContext.Provider
      value={{
        admins,
        isLoading,
        isCreating,
        editingAdmin,
        setIsCreating,
        setEditingAdmin,
        loadAdmins,
        createAdmin,
        updateAdmin,
        deleteAdmin,
        toggleStatus
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }
  return context;
}