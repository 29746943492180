import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase/client';

interface DashboardStats {
  totalTeams: number;
  activeEvents: number;
  completedEvents: number;
}

export function useDashboardStats() {
  const [stats, setStats] = useState<DashboardStats>({
    totalTeams: 0,
    activeEvents: 0,
    completedEvents: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchStats() {
      try {
        const [teamsCount, eventsData] = await Promise.all([
          supabase.from('registrations').select('count', { count: 'exact' }),
          supabase.from('events').select('status')
        ]);

        const activeEvents = eventsData.data?.filter(e => e.status === 'active').length || 0;
        const completedEvents = eventsData.data?.filter(e => e.status === 'completed').length || 0;

        setStats({
          totalTeams: teamsCount.count || 0,
          activeEvents,
          completedEvents
        });
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchStats();
  }, []);

  return { stats, isLoading };
}