import React from 'react';
import { Edit2, Trash2 } from 'lucide-react';
import { Admin } from '../../types/admin';
import { authService } from '../../services/auth.service';
import { ROLES } from '../../constants';

interface AdminListProps {
  admins: Admin[];
  onEdit: (admin: Admin) => void;
  onDelete: (admin: Admin) => void;
  onToggleStatus: (admin: Admin) => void;
}

export function AdminList({ admins, onEdit, onDelete, onToggleStatus }: AdminListProps) {
  const currentRole = authService.getRole();
  const currentUserId = authService.getUserId();

  const canEdit = (admin: Admin) => {
    if (currentRole === ROLES.SUPERADMIN) return true;
    if (currentRole === ROLES.ADMIN) return admin.role === ROLES.ORGANIZER;
    return admin.id === currentUserId;
  };

  const canDelete = (admin: Admin) => {
    if (currentRole === ROLES.SUPERADMIN) return true;
    if (currentRole === ROLES.ADMIN) return admin.role === ROLES.ORGANIZER;
    return false;
  };

  const canToggleStatus = (admin: Admin) => {
    if (currentRole === ROLES.SUPERADMIN) return true;
    if (currentRole === ROLES.ADMIN) return admin.role === ROLES.ORGANIZER;
    return false;
  };

  return (
    <div className="bg-white border border-gray-200 rounded-xl overflow-hidden">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50">
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">Utilisateur</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">Email</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">Box</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">Rôle</th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-600">Statut</th>
            <th className="px-6 py-3 text-right text-sm font-medium text-gray-600">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {admins.map((admin) => (
            <tr key={admin.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="font-medium text-gray-900">{admin.username}</div>
              </td>
              <td className="px-6 py-4">
                <div className="text-gray-600">{admin.email}</div>
              </td>
              <td className="px-6 py-4">
                <div className="text-gray-600">{admin.boxName || '-'}</div>
              </td>
              <td className="px-6 py-4">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  admin.role === ROLES.SUPERADMIN 
                    ? 'bg-red-100 text-red-800'
                    : admin.role === ROLES.ADMIN
                    ? 'bg-purple-100 text-purple-800'
                    : 'bg-blue-100 text-blue-800'
                }`}>
                  {admin.role}
                </span>
              </td>
              <td className="px-6 py-4">
                {canToggleStatus(admin) ? (
                  <button
                    onClick={() => onToggleStatus(admin)}
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      admin.status === 'active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {admin.status === 'active' ? 'Actif' : 'Inactif'}
                  </button>
                ) : (
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    admin.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {admin.status === 'active' ? 'Actif' : 'Inactif'}
                  </span>
                )}
              </td>
              <td className="px-6 py-4">
                <div className="flex justify-end gap-2">
                  {canEdit(admin) && (
                    <button
                      onClick={() => onEdit(admin)}
                      className="p-2 bg-blue-50 text-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                  )}
                  {canDelete(admin) && (
                    <button
                      onClick={() => onDelete(admin)}
                      className="p-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}