export type EventType = 'individual' | 'team';
export type EventLevel = 'rx' | 'scaled' | 'beginner';
export type EventStatus = 'draft' | 'published' | 'closed' | 'completed';

export interface EventLocation {
  name: string;
  address: string;
  city: string;
  postalCode: string;
}

export interface Event {
  id: string;
  name: string;
  description: string;
  type: EventType;
  date: string;
  boxName: string;
  location: EventLocation;
  maxParticipants: number;
  teamSize?: number;
  allowedLevels: EventLevel[];
  registrationDeadline: string;
  requiresQualification: boolean;
  status: EventStatus;
  createdAt?: string;
  updatedAt?: string;
}

export interface EventFormData extends Omit<Event, 'id' | 'createdAt' | 'updatedAt' | 'status'> {}

export const DEFAULT_EVENT_DATA: EventFormData = {
  name: '',
  description: '',
  type: 'individual',
  date: new Date().toISOString().slice(0, 16),
  boxName: '',
  location: {
    name: '',
    address: '',
    city: '',
    postalCode: ''
  },
  maxParticipants: 30,
  teamSize: 3,
  allowedLevels: ['rx', 'scaled'],
  registrationDeadline: new Date().toISOString().slice(0, 16),
  requiresQualification: false
};