import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminSidebar } from '../components/admin/AdminSidebar';
import { Footer } from '../components/Footer';

export default function AdminLayout() {
  return (
    <div className="min-h-screen flex bg-gray-50">
      <AdminSidebar />
      
      <div className="flex-1 flex flex-col min-h-screen">
        <main className="flex-grow p-6">
          <div className="max-w-7xl mx-auto w-full">
            <Outlet />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}