import { Event, EventFormData } from '../types/event';

export function validateEventData(data: Partial<EventFormData>): boolean {
  try {
    // Required fields
    if (!data.name?.trim()) return false;
    if (!data.type) return false;
    if (!data.date) return false;
    if (!data.boxName?.trim()) return false;
    if (!data.maxParticipants || data.maxParticipants <= 0) return false;
    if (!data.registrationDeadline) return false;

    // Location validation
    if (!data.location) return false;
    if (!data.location.name?.trim()) return false;
    if (!data.location.address?.trim()) return false;
    if (!data.location.city?.trim()) return false;
    if (!data.location.postalCode?.trim()) return false;

    // Team size validation
    if (data.type === 'team' && (!data.teamSize || data.teamSize <= 0)) {
      return false;
    }

    // Dates validation
    const eventDate = new Date(data.date);
    const registrationDeadline = new Date(data.registrationDeadline);
    if (registrationDeadline > eventDate) return false;

    // Allowed levels validation
    if (!data.allowedLevels?.length) return false;
    const validLevels = ['rx', 'scaled', 'beginner'];
    if (!data.allowedLevels.every(level => validLevels.includes(level))) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('Event validation error:', error);
    return false;
  }
}

export function formatEventData(data: Partial<EventFormData>): Record<string, any> {
  const formatted: Record<string, any> = {
    name: data.name?.trim(),
    description: data.description?.trim(),
    type: data.type,
    box_name: data.boxName?.trim(),
    location: data.location && {
      name: data.location.name?.trim(),
      address: data.location.address?.trim(),
      city: data.location.city?.trim(),
      postalCode: data.location.postalCode?.trim()
    },
    max_participants: data.maxParticipants,
    team_size: data.type === 'team' ? data.teamSize : null,
    allowed_levels: data.allowedLevels || ['rx', 'scaled'],
    requires_qualification: data.requiresQualification || false
  };

  // Format dates with timezone
  if (data.date) {
    const date = new Date(data.date);
    formatted.date = date.toISOString();
  }

  if (data.registrationDeadline) {
    const deadline = new Date(data.registrationDeadline);
    formatted.registration_deadline = deadline.toISOString();
  }

  // Remove undefined/null values
  Object.keys(formatted).forEach(key => {
    if (formatted[key] == null) {
      delete formatted[key];
    }
  });

  return formatted;
}