import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Menu, X, Home, Calendar, Users, Dumbbell, 
  Trophy, UserCheck, UserPlus, LogOut, ChevronRight
} from 'lucide-react';
import { useAuth } from '../../hooks/auth/useAuth';
import { ROLES } from '../../constants';

const menuItems = [
  {
    label: 'Dashboard',
    path: '/admin/dashboard',
    icon: Home
  },
  {
    label: 'Événements',
    path: '/admin/events',
    icon: Calendar
  },
  {
    label: 'Athlètes',
    path: '/admin/athletes',
    icon: Users
  },
  {
    label: 'WODs',
    path: '/admin/wods',
    icon: Dumbbell
  },
  {
    label: 'Classements',
    path: '/admin/rankings',
    icon: Trophy
  },
  {
    label: 'Bénévoles',
    path: '/admin/volunteers',
    icon: UserCheck
  },
  {
    label: 'Comptes',
    path: '/admin/accounts',
    icon: UserPlus,
    roles: [ROLES.ADMIN, ROLES.SUPERADMIN]
  }
];

export function AdminSidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const location = useLocation();
  const { logout, getRole } = useAuth();
  const currentRole = getRole();

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);
  const toggleMobile = () => setIsMobileOpen(!isMobileOpen);

  const filteredMenuItems = menuItems.filter(item => 
    !item.roles || (item.roles && currentRole && item.roles.includes(currentRole))
  );

  const sidebarContent = (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="h-16 flex items-center justify-between px-4 border-b border-gray-200">
        <span className={`font-semibold transition-all ${isCollapsed ? 'text-lg' : 'text-xl'}`}>
          {isCollapsed ? 'CFL' : 'Admin'}
        </span>
        <button 
          onClick={toggleSidebar}
          className="hidden lg:flex items-center justify-center w-8 h-8 hover:bg-gray-100 rounded-lg"
        >
          <ChevronRight className={`w-5 h-5 transition-transform ${isCollapsed ? '' : 'rotate-180'}`} />
        </button>
      </div>

      {/* Navigation */}
      <nav className="flex-1 overflow-y-auto p-4 space-y-2">
        {filteredMenuItems.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.path;
          
          return (
            <Link
              key={item.path}
              to={item.path}
              className={`
                flex items-center gap-3 px-3 py-2 rounded-lg transition-colors
                ${isActive 
                  ? 'bg-blue-50 text-blue-600' 
                  : 'text-gray-600 hover:bg-gray-50'
                }
              `}
              onClick={() => setIsMobileOpen(false)}
            >
              <Icon className="w-5 h-5 flex-shrink-0" />
              {!isCollapsed && <span className="whitespace-nowrap">{item.label}</span>}
            </Link>
          );
        })}
      </nav>

      {/* Footer */}
      <div className="p-4 border-t border-gray-200">
        <button
          onClick={logout}
          className="flex items-center gap-3 w-full px-3 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
        >
          <LogOut className="w-5 h-5 flex-shrink-0" />
          {!isCollapsed && <span>Déconnexion</span>}
        </button>
      </div>
    </div>
  );

  return (
    <>
      {/* Mobile Toggle Button */}
      <button
        onClick={toggleMobile}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 bg-white rounded-lg shadow-md"
      >
        {isMobileOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {/* Mobile Overlay */}
      {isMobileOpen && (
        <div 
          className="lg:hidden fixed inset-0 bg-black/50 z-30"
          onClick={() => setIsMobileOpen(false)}
        />
      )}

      {/* Desktop Sidebar */}
      <aside className={`
        fixed lg:sticky top-0 left-0 h-full bg-white border-r border-gray-200 z-40
        transition-all duration-300 transform
        ${isMobileOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        ${isCollapsed ? 'w-20' : 'w-64'}
      `}>
        {sidebarContent}
      </aside>
    </>
  );
}