import { supabase } from '../../lib/supabase';
import { Admin, AdminFormData } from '../../types/admin';
import { DatabaseError } from '../../types/errors';
import { authService } from '../auth/auth.service';
import { API_ERROR_MESSAGES } from '../../constants';
import { ROLES } from '../../constants';

class AdminService {
  async getAdmins(): Promise<Admin[]> {
    try {
      const currentRole = authService.getRole();
      const currentUserId = authService.getUserId();
      
      let query = supabase.from('admins').select('*');

      // Filter based on user role
      if (currentRole === ROLES.ADMIN) {
        query = query.eq('role', ROLES.ORGANIZER);
      } else if (currentRole === ROLES.ORGANIZER) {
        query = query.eq('id', currentUserId);
      }

      const { data, error } = await query.order('created_at', { ascending: false });

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
      return data || [];
    } catch (error) {
      console.error('Error fetching admins:', error);
      throw error;
    }
  }

  async createAdmin(adminData: AdminFormData): Promise<Admin> {
    try {
      const currentRole = authService.getRole();
      
      if (!authService.isAdmin()) {
        throw new DatabaseError(API_ERROR_MESSAGES.UNAUTHORIZED);
      }

      if (currentRole === ROLES.ADMIN && adminData.role !== ROLES.ORGANIZER) {
        throw new DatabaseError(API_ERROR_MESSAGES.UNAUTHORIZED);
      }

      const { data, error } = await supabase
        .from('admins')
        .insert([{
          username: adminData.username.trim(),
          email: adminData.email.trim().toLowerCase(),
          password: adminData.password,
          role: adminData.role,
          box_name: adminData.boxName?.trim(),
          status: 'active'
        }])
        .select()
        .single();

      if (error) {
        if (error.code === '23505') {
          throw new DatabaseError('Cet identifiant ou email est déjà utilisé');
        }
        throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
      }

      return data;
    } catch (error) {
      console.error('Error creating admin:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }

  async updateAdmin(id: string, adminData: AdminFormData): Promise<Admin> {
    try {
      const currentRole = authService.getRole();
      const currentUserId = authService.getUserId();

      // Check permissions
      if (currentRole === ROLES.ORGANIZER && id !== currentUserId) {
        throw new DatabaseError(API_ERROR_MESSAGES.UNAUTHORIZED);
      }

      const updates: Record<string, any> = {
        username: adminData.username?.trim(),
        email: adminData.email?.trim().toLowerCase(),
        box_name: adminData.boxName?.trim()
      };

      if (authService.isSuperAdmin() && adminData.role) {
        updates.role = adminData.role;
      }

      if (adminData.password) {
        updates.password = adminData.password;
      }

      const { data, error } = await supabase
        .from('admins')
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
      return data;
    } catch (error) {
      console.error('Error updating admin:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }

  async deleteAdmin(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('admins')
        .delete()
        .eq('id', id);

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    } catch (error) {
      console.error('Error deleting admin:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }

  async updateStatus(id: string, status: 'active' | 'inactive'): Promise<void> {
    try {
      const { error } = await supabase
        .from('admins')
        .update({ status })
        .eq('id', id);

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    } catch (error) {
      console.error('Error updating status:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }
}

export const adminService = new AdminService();