import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { authService } from '../../services/auth/auth.service';
import { LoginCredentials } from '../../types/auth';
import { AuthError } from '../../types/errors';

export function useAuth() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const login = async (credentials: LoginCredentials) => {
    setIsLoading(true);
    setError(null);

    try {
      await authService.login(credentials.username, credentials.password);
      toast.success('Connexion réussie');
      navigate('/admin/dashboard', { replace: true });
    } catch (err) {
      const message = err instanceof AuthError ? err.message : 'Erreur lors de la connexion';
      setError(message);
      toast.error(message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    authService.logout();
    toast.success('Déconnexion réussie');
    navigate('/admin', { replace: true });
  };

  return {
    login,
    logout,
    isLoading,
    error,
    isAuthenticated: authService.isAuthenticated(),
    isSuperAdmin: authService.isSuperAdmin(),
    isAdmin: authService.isAdmin(),
    getRole: authService.getRole.bind(authService),
    getUserId: authService.getUserId.bind(authService)
  };
}