import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from '../lib/supabase/auth';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: string[];
}

export function ProtectedRoute({ children, requiredRole }: ProtectedRouteProps) {
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();
  const userRole = auth.getRole();

  if (!isAuthenticated) {
    return <Navigate to="/admin" replace state={{ from: location }} />;
  }

  if (requiredRole && userRole && !requiredRole.includes(userRole)) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return <>{children}</>;
}