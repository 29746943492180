import React from 'react';
import { AdminProvider } from '../contexts/AdminContext';
import { AdminDashboard } from '../components/admin/AdminDashboard';

export default function AdminManagement() {
  return (
    <AdminProvider>
      <AdminDashboard />
    </AdminProvider>
  );
}