import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { EventList } from '../components/events/EventList';
import { Event } from '../types/event';
import { Calendar, Trophy } from 'lucide-react';

export default function Registration() {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('status', 'published')
        .gte('date', new Date().toISOString())
        .order('date', { ascending: true });

      if (error) throw error;
      setEvents(data || []);
    } catch (error) {
      console.error('Erreur lors du chargement des événements:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <Header />

        <div className="bg-white border border-gray-200 rounded-xl p-8 mb-12 shadow-sm">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-blue-500 text-white rounded-full mb-6">
              <Calendar className="w-8 h-8" />
            </div>
            <h2 className="text-3xl font-semibold text-gray-900 mb-4">
              Événements CrossFit
            </h2>
            <p className="text-lg text-gray-600">
              Découvrez et inscrivez-vous aux prochaines compétitions de CrossFit. 
              Des événements pour tous les niveaux, en individuel ou en équipe.
            </p>
          </div>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
          </div>
        ) : events.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-xl border border-gray-200 shadow-sm">
            <Trophy className="w-16 h-16 text-gray-400 mx-auto mb-6" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Aucun événement à venir
            </h3>
            <p className="text-gray-600 max-w-md mx-auto">
              Il n'y a pas d'événements programmés pour le moment. 
              Revenez bientôt pour découvrir les prochaines compétitions.
            </p>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl font-light text-gray-900">
                Événements à venir
              </h2>
              <div className="text-sm text-gray-500">
                {events.length} événement{events.length > 1 ? 's' : ''} trouvé{events.length > 1 ? 's' : ''}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6">
              <EventList events={events} />
            </div>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}