import { EventFormData } from '../../types/event';

export function formatEventData(data: Partial<EventFormData>): Record<string, any> {
  const formatted: Record<string, any> = {
    name: data.name?.trim(),
    description: data.description?.trim(),
    type: data.type,
    box_name: data.boxName?.trim(),
    location: formatEventLocation(data.location),
    max_participants: data.maxParticipants,
    team_size: data.type === 'team' ? data.teamSize : null,
    allowed_levels: data.allowedLevels || ['rx', 'scaled'],
    requires_qualification: data.requiresQualification || false
  };

  if (data.date) {
    formatted.date = new Date(data.date).toISOString();
  }

  if (data.registrationDeadline) {
    formatted.registration_deadline = new Date(data.registrationDeadline).toISOString();
  }

  return removeEmptyValues(formatted);
}

function formatEventLocation(location: any) {
  if (!location) return null;
  return {
    name: location.name?.trim(),
    address: location.address?.trim(),
    city: location.city?.trim(),
    postalCode: location.postalCode?.trim()
  };
}

function removeEmptyValues(obj: Record<string, any>): Record<string, any> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value != null) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
}