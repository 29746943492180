import { supabase } from '../../lib/supabase/client';
import { Event, EventFormData } from '../../types/event';
import { validateEventData } from '../../utils/validation/eventValidation';
import { formatEventData } from '../../utils/formatting/eventFormatting';
import { DatabaseError } from '../../types/errors';

class EventService {
  async getEvents(): Promise<Event[]> {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .order('date', { ascending: true });

    if (error) throw new DatabaseError('Failed to fetch events');
    return data || [];
  }

  async createEvent(eventData: EventFormData): Promise<Event> {
    if (!validateEventData(eventData)) {
      throw new DatabaseError('Invalid event data');
    }

    const formattedData = formatEventData(eventData);
    const { data, error } = await supabase
      .from('events')
      .insert([formattedData])
      .select()
      .single();

    if (error) throw new DatabaseError('Failed to create event');
    return data;
  }

  async updateEvent(id: string, eventData: Partial<EventFormData>): Promise<Event> {
    if (!validateEventData(eventData)) {
      throw new DatabaseError('Invalid event data');
    }

    const formattedData = formatEventData(eventData);
    const { data, error } = await supabase
      .from('events')
      .update(formattedData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw new DatabaseError('Failed to update event');
    return data;
  }

  async deleteEvent(id: string): Promise<void> {
    const { error } = await supabase
      .from('events')
      .delete()
      .eq('id', id);

    if (error) throw new DatabaseError('Failed to delete event');
  }
}

export const eventService = new EventService();