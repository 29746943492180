import { z } from 'zod';
import { AdminFormData } from '../types/admin';
import { EventFormData } from '../types/event';

// ... autres validations ...

export function validateEventForm(data: EventFormData): Partial<Record<string, string>> {
  const errors: Partial<Record<string, string>> = {};

  if (!data.name?.trim()) {
    errors.name = 'Le nom est requis';
  }

  if (!data.boxName?.trim()) {
    errors.boxName = 'Le nom de la box est requis';
  }

  if (!data.type) {
    errors.type = 'Le type d\'événement est requis';
  }

  if (!data.maxParticipants || data.maxParticipants <= 0) {
    errors.maxParticipants = 'Le nombre de participants doit être supérieur à 0';
  }

  if (data.type === 'team' && (!data.teamSize || data.teamSize < 2)) {
    errors.teamSize = 'La taille de l\'équipe doit être d\'au moins 2';
  }

  if (!data.date) {
    errors.date = 'La date est requise';
  }

  if (!data.registrationDeadline) {
    errors.registrationDeadline = 'La date limite d\'inscription est requise';
  }

  // Validation des champs de localisation
  if (!data.location?.name?.trim()) {
    errors['location.name'] = 'Le nom du lieu est requis';
  }

  if (!data.location?.address?.trim()) {
    errors['location.address'] = 'L\'adresse est requise';
  }

  if (!data.location?.city?.trim()) {
    errors['location.city'] = 'La ville est requise';
  }

  if (!data.location?.postalCode?.trim()) {
    errors['location.postalCode'] = 'Le code postal est requis';
  }

  return errors;
}