import React from 'react';
import { XCircle } from 'lucide-react';

interface ErrorMessageProps {
  message: string;
  className?: string;
}

export function ErrorMessage({ message, className = '' }: ErrorMessageProps) {
  return (
    <div className={`flex items-center gap-2 text-red-600 ${className}`}>
      <XCircle className="w-4 h-4" />
      <span className="text-sm">{message}</span>
    </div>
  );
}