import { supabase } from './client';
import { AuthError } from '../../types/errors';
import { AdminRole } from '../../types/admin';

interface AuthSession {
  isAuthenticated: boolean;
  id: string;
  username: string;
  role: AdminRole;
  expiresAt: number;
}

class Auth {
  private readonly SESSION_KEY = 'auth_session';
  private readonly SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

  private getSession(): AuthSession | null {
    try {
      const session = sessionStorage.getItem(this.SESSION_KEY);
      return session ? JSON.parse(session) : null;
    } catch {
      return null;
    }
  }

  private setSession(session: AuthSession): void {
    sessionStorage.setItem(this.SESSION_KEY, JSON.stringify(session));
  }

  private clearSession(): void {
    sessionStorage.removeItem(this.SESSION_KEY);
  }

  async login(username: string, password: string): Promise<void> {
    try {
      if (!username?.trim() || !password?.trim()) {
        throw new AuthError('Les identifiants sont requis');
      }

      const { data, error } = await supabase
        .from('admins')
        .select('id, username, role, status')
        .eq('username', username.trim())
        .eq('password', password.trim())
        .single();

      if (error || !data) {
        throw new AuthError('Identifiants invalides');
      }

      if (data.status === 'inactive') {
        throw new AuthError('Ce compte est désactivé');
      }

      this.setSession({
        isAuthenticated: true,
        id: data.id,
        username: data.username,
        role: data.role as AdminRole,
        expiresAt: Date.now() + this.SESSION_DURATION
      });
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  isAuthenticated(): boolean {
    const session = this.getSession();
    if (!session) return false;

    if (Date.now() > session.expiresAt) {
      this.clearSession();
      return false;
    }

    return session.isAuthenticated;
  }

  isSuperAdmin(): boolean {
    const session = this.getSession();
    return session?.role === 'superadmin';
  }

  isAdmin(): boolean {
    const session = this.getSession();
    return session?.role === 'superadmin' || session?.role === 'admin';
  }

  getRole(): AdminRole | null {
    const session = this.getSession();
    return session?.role || null;
  }

  getUserId(): string | null {
    const session = this.getSession();
    return session?.id || null;
  }

  logout(): void {
    this.clearSession();
  }
}

export const auth = new Auth();