import { supabase } from '../lib/supabase';
import { AuthError } from '../types/errors';
import { AuthSession, AuthUser, LoginCredentials } from '../types/auth';
import { ROLES } from '../constants';

const SESSION_KEY = 'auth_session';
const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

class AuthService {
  private getSession(): AuthSession | null {
    try {
      const session = sessionStorage.getItem(SESSION_KEY);
      return session ? JSON.parse(session) : null;
    } catch {
      return null;
    }
  }

  private setSession(session: AuthSession): void {
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(session));
  }

  private clearSession(): void {
    sessionStorage.removeItem(SESSION_KEY);
  }

  async login({ username, password }: LoginCredentials): Promise<AuthUser> {
    try {
      if (!username?.trim() || !password?.trim()) {
        throw new AuthError('Les identifiants sont requis');
      }

      const { data, error } = await supabase
        .from('admins')
        .select('id, username, email, role, status')
        .eq('username', username.trim())
        .eq('password', password.trim())
        .single();

      if (error) throw new AuthError('Identifiants invalides');
      if (!data) throw new AuthError('Identifiants invalides');
      if (data.status === 'inactive') throw new AuthError('Ce compte est désactivé');

      const user: AuthUser = {
        id: data.id,
        username: data.username,
        email: data.email,
        role: data.role,
        status: data.status
      };

      // Update last login
      await supabase
        .from('admins')
        .update({ last_login: new Date().toISOString() })
        .eq('id', user.id);

      // Create session
      this.setSession({
        isAuthenticated: true,
        userId: user.id,
        username: user.username,
        role: user.role,
        expiresAt: Date.now() + SESSION_DURATION
      });

      return user;
    } catch (error) {
      console.error('Login error:', error);
      if (error instanceof AuthError) throw error;
      throw new AuthError('Erreur lors de la connexion');
    }
  }

  isAuthenticated(): boolean {
    const session = this.getSession();
    if (!session) return false;

    if (Date.now() > session.expiresAt) {
      this.clearSession();
      return false;
    }

    return session.isAuthenticated;
  }

  isSuperAdmin(): boolean {
    const session = this.getSession();
    return session?.role === ROLES.SUPERADMIN;
  }

  isAdmin(): boolean {
    const session = this.getSession();
    return session?.role === ROLES.ADMIN || session?.role === ROLES.SUPERADMIN;
  }

  getRole(): string | null {
    const session = this.getSession();
    return session?.role || null;
  }

  getUserId(): string | null {
    const session = this.getSession();
    return session?.userId || null;
  }

  logout(): void {
    this.clearSession();
  }

  refreshSession(): void {
    const session = this.getSession();
    if (session) {
      session.expiresAt = Date.now() + SESSION_DURATION;
      this.setSession(session);
    }
  }
}

export const authService = new AuthService();