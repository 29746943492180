import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

export function formatDate(date: Date | string | null | undefined, formatStr = 'PPP à HH:mm'): string {
  if (!date) return '-';
  
  try {
    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return isValid(dateObj) ? format(dateObj, formatStr, { locale: fr }) : '-';
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
}

export function formatDateRange(startDate: Date | string, endDate: Date | string): string {
  try {
    const start = typeof startDate === 'string' ? parseISO(startDate) : startDate;
    const end = typeof endDate === 'string' ? parseISO(endDate) : endDate;
    
    if (!isValid(start) || !isValid(end)) return '-';
    
    return `${format(start, 'PPP', { locale: fr })} - ${format(end, 'PPP', { locale: fr })}`;
  } catch (error) {
    console.error('Error formatting date range:', error);
    return '-';
  }
}