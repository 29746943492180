import { RegistrationFormData } from '../../types/registration';

interface ValidationErrors {
  [key: string]: string;
}

const validateTeamInfo = (data: RegistrationFormData): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!data.teamName?.trim()) {
    errors.teamName = 'Le nom de l\'équipe est requis';
  }

  if (!data.boxLocation?.trim()) {
    errors.boxLocation = 'La box CrossFit est requise';
  }

  return errors;
};

const validateContactInfo = (data: RegistrationFormData): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!data.email?.trim()) {
    errors.email = 'L\'email est requis';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = 'L\'email n\'est pas valide';
  }

  if (!data.phone?.trim()) {
    errors.phone = 'Le numéro de téléphone est requis';
  } else if (!/^(\+33|0)[1-9](\d{8})$/.test(data.phone.replace(/\s/g, ''))) {
    errors.phone = 'Le numéro de téléphone n\'est pas valide';
  }

  return errors;
};

const validateAthleteInfo = (athlete: any, index: number): ValidationErrors => {
  const errors: ValidationErrors = {};
  const prefix = `athletes.${index}`;

  if (!athlete.name?.trim()) {
    errors[`${prefix}.name`] = 'Le nom est requis';
  }

  if (!athlete.gender) {
    errors[`${prefix}.gender`] = 'Le genre est requis';
  }

  if (!athlete.tshirtSize) {
    errors[`${prefix}.tshirtSize`] = 'La taille du t-shirt est requise';
  }

  if (athlete.insta && !athlete.insta.startsWith('@')) {
    errors[`${prefix}.insta`] = 'Le nom d\'utilisateur Instagram doit commencer par @';
  }

  return errors;
};

export const validateRegistrationForm = (data: RegistrationFormData): ValidationErrors => {
  let errors: ValidationErrors = {
    ...validateTeamInfo(data),
    ...validateContactInfo(data)
  };

  // Validate each athlete
  data.athletes.forEach((athlete, index) => {
    const athleteErrors = validateAthleteInfo(athlete, index);
    errors = { ...errors, ...athleteErrors };
  });

  return errors;
};