import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Lock, Home, User, KeyRound } from 'lucide-react';
import { useAuth } from '../hooks/auth/useAuth';
import { useForm } from '../hooks/useForm';
import { LoginCredentials } from '../types/auth';
import { Footer } from '../components/Footer';
import { LoadingSpinner } from '../components/common/LoadingSpinner';
import { ErrorMessage } from '../components/common/ErrorMessage';

const initialValues: LoginCredentials = {
  username: '',
  password: ''
};

export default function AdminLogin() {
  const { login, isLoading, error } = useAuth();
  const { values, handleChange, handleSubmit } = useForm<LoginCredentials>({
    initialValues,
    onSubmit: login
  });

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Link 
        to="/"
        className="fixed top-4 left-4 inline-flex items-center gap-2 px-4 py-2 bg-white hover:bg-gray-100 rounded-lg transition-colors text-gray-600"
      >
        <Home className="w-4 h-4" />
        Accueil
      </Link>

      <div className="flex-grow flex items-center justify-center px-4">
        <div className="w-full max-w-md">
          <div className="bg-white shadow-sm rounded-xl p-8 border border-gray-200">
            <div className="text-center mb-8">
              <div className="inline-flex items-center justify-center w-16 h-16 bg-gray-900 text-white rounded-full mb-4">
                <Lock className="w-8 h-8" />
              </div>
              <h1 className="text-2xl font-semibold text-gray-900">Administration</h1>
              <p className="text-sm text-gray-500 mt-1">Connectez-vous pour accéder au tableau de bord</p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-1.5">
                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                  Identifiant
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="username"
                    name="username"
                    type="text"
                    value={values.username}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900 transition-colors"
                    placeholder="Entrez votre identifiant"
                    autoComplete="username"
                    required
                  />
                </div>
              </div>

              <div className="space-y-1.5">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Mot de passe
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <KeyRound className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    className="block w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:ring-2 focus:ring-gray-900 focus:border-gray-900 transition-colors"
                    placeholder="Entrez votre mot de passe"
                    autoComplete="current-password"
                    required
                  />
                </div>
              </div>

              {error && (
                <ErrorMessage message={error} className="mt-4" />
              )}

              <button
                type="submit"
                disabled={isLoading || !values.username || !values.password}
                className="w-full px-4 py-2.5 bg-gray-900 hover:bg-gray-800 disabled:bg-gray-300 text-white rounded-lg transition-colors flex items-center justify-center gap-2 font-medium"
              >
                {isLoading ? (
                  <>
                    <LoadingSpinner size="sm" className="border-white" />
                    <span>Connexion en cours...</span>
                  </>
                ) : (
                  'Se connecter'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}