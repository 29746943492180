import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminLayout from '../layouts/AdminLayout';
import { ProtectedRoute } from '../components/ProtectedRoute';

// Pages
import Registration from '../pages/Registration';
import EventRegistration from '../pages/EventRegistration';
import AdminLogin from '../pages/AdminLogin';
import AdminDashboard from '../pages/AdminDashboard';
import EventManagement from '../pages/EventManagement';
import AthleteManagement from '../pages/AthleteManagement';
import WodManagement from '../pages/WodManagement';
import RankingManagement from '../pages/RankingManagement';
import VolunteerManagement from '../pages/VolunteerManagement';
import AdminManagement from '../pages/AdminManagement';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Registration />} />
      <Route path="/events/:id/register" element={<EventRegistration />} />
      <Route path="/admin" element={<AdminLogin />} />
      
      <Route 
        path="/admin" 
        element={
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="events" element={<EventManagement />} />
        <Route path="athletes" element={<AthleteManagement />} />
        <Route path="wods" element={<WodManagement />} />
        <Route path="rankings" element={<RankingManagement />} />
        <Route path="volunteers" element={<VolunteerManagement />} />
        <Route 
          path="accounts" 
          element={
            <ProtectedRoute requiredRole={['admin', 'superadmin']}>
              <AdminManagement />
            </ProtectedRoute>
          } 
        />
      </Route>
    </Routes>
  );
}