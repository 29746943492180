import { supabase } from '../../lib/supabase';
import { RegistrationFormData, Registration } from '../../types/registration';
import { DatabaseError } from '../../types/errors';
import { API_ERROR_MESSAGES } from '../../constants/api';

class RegistrationService {
  async createRegistration(eventId: string, data: RegistrationFormData): Promise<Registration> {
    try {
      const { data: registration, error } = await supabase
        .from('registrations')
        .insert([{
          event_id: eventId,
          team_name: data.teamName.trim(),
          email: data.email.trim().toLowerCase(),
          phone: data.phone.trim(),
          box_location: data.boxLocation.trim(),
          athlete1_name: data.athletes[0].name.trim(),
          athlete1_gender: data.athletes[0].gender,
          athlete1_insta: data.athletes[0].insta?.trim(),
          athlete1_tshirt: data.athletes[0].tshirtSize,
          athlete2_name: data.athletes[1].name.trim(),
          athlete2_gender: data.athletes[1].gender,
          athlete2_insta: data.athletes[1].insta?.trim(),
          athlete2_tshirt: data.athletes[1].tshirtSize,
          athlete3_name: data.athletes[2].name.trim(),
          athlete3_gender: data.athletes[2].gender,
          athlete3_insta: data.athletes[2].insta?.trim(),
          athlete3_tshirt: data.athletes[2].tshirtSize,
          status: 'pending'
        }])
        .select()
        .single();

      if (error) {
        if (error.code === '23505') {
          throw new DatabaseError('Cette équipe ou cet email est déjà inscrit');
        }
        throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
      }

      return registration;
    } catch (error) {
      console.error('Error creating registration:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }

  async getRegistrations(eventId: string): Promise<Registration[]> {
    try {
      const { data, error } = await supabase
        .from('registrations')
        .select('*')
        .eq('event_id', eventId)
        .order('created_at', { ascending: false });

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
      return data || [];
    } catch (error) {
      console.error('Error fetching registrations:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }

  async updateRegistrationStatus(id: string, status: 'approved' | 'rejected'): Promise<void> {
    try {
      const { error } = await supabase
        .from('registrations')
        .update({ status })
        .eq('id', id);

      if (error) throw new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    } catch (error) {
      console.error('Error updating registration status:', error);
      throw error instanceof DatabaseError ? error : new DatabaseError(API_ERROR_MESSAGES.DEFAULT);
    }
  }
}

export const registrationService = new RegistrationService();